export const connectSrcStandard = `
https://api.gateway.commonground.nu 
https://api.common-gateway.commonground.nu 
https://raw.githubusercontent.com/ConductionNL/ 
https://api.github.com https://conductionnl.github.io 
https://api.accept.common-gateway.commonground.nu
https://opencatalogi.accept.commonground.nu
`;
export const connectSrcMunicipalities = `
https://albrandswaard.openwoo.app
https://albrandswaard.accept.openwoo.app
https://barendrecht.openwoo.app
https://barendrecht.accept.openwoo.app
https://buren.openwoo.app
https://buren.accept.openwoo.app
https://dinkelland.openwoo.app
https://dinkelland.accept.openwoo.app
https://ede.openwoo.app
https://ede.accept.openwoo.app
https://epe.openwoo.app
https://epe.accept.openwoo.app
https://gooisemeren.openwoo.app
https://gooisemeren.accept.openwoo.app
https://gouda.openwoo.app
https://gouda.accept.openwoo.app
https://gemeentehw.openwoo.app
https://gemeentehw.accept.openwoo.app
https://hofvantwente.openwoo.app
https://hofvantwente.accept.openwoo.app
https://lansingerland.openwoo.app
https://lansingerland.accept.openwoo.app
https://leiden.openwoo.app
https://leiden.accept.openwoo.app
https://moerdijk.openwoo.app
https://moerdijk.accept.openwoo.app
https://noordwijk.openwoo.app
https://noordwijk.accept.openwoo.app
https://ridderkerk.openwoo.app
https://ridderkerk.accept.openwoo.app
https://rijssen-holten.openwoo.app
https://rijssen-holten.accept.openwoo.app
https://roosendaal.openwoo.app
https://roosendaal.accept.openwoo.app
https://rotterdam.openwoo.app
https://rotterdam.accept.openwoo.app
https://stedebroec.openwoo.app
https://stedebroec.accept.openwoo.app
https://texel.openwoo.app
https://texel.accept.openwoo.app
https://tubbergen.openwoo.app
https://tubbergen.accept.openwoo.app
https://waddinxveen.openwoo.app
https://waddinxveen.accept.openwoo.app
https://zutphen.openwoo.app
https://zutphen.accept.openwoo.app
https://noordwijk.accept.commonground.nu
https://ede.accept.commonground.nu
`;

export const connectSrcOther = `
https://conduction.openwoo.app
https://conduction.accept.openwoo.app
https://noaberkracht.openwoo.app
https://noaberkracht.accept.openwoo.app
https://openwoo.app
https://open-webconcept.accept.openwoo.app
https://sloterburg.openwoo.app
https://sloterburg.accept.openwoo.app
https://xxllnc.openwoo.app
https://xxllnc.accept.openwoo.app
https://zuiddrecht.openwoo.app
https://zuiddrecht.accept.openwoo.app
https://tilburg.accept.commonground.nu
https://bct.accept.commonground.nu
`;

export const connectSrcLocal = `
http://localhost:8000 
http://localhost:8080
http://localhost:3000
`;
